/* --------------------
Gallery Item 
------------------- */
.filters-button-group {
  margin: -7.5px;
  flex-wrap: wrap;
  button {
    height: 36px;
    padding: 0 20px;
    width: auto;
    border: 0 none;
    background: #383030;
    border-radius: 3px;
    color: var(--color-heading);
    margin: 7.5px;
    font-weight: 600;
    transition: 0.4s;

    &.is-checked,
    &:hover {
      background: var(--color-primary);
      color: var(--color-white);
    }
  }
}
.filters-button-group2 {
  margin: -7.5px;
  flex-wrap: wrap;
  button {
    height: 36px;
    padding: 0 20px;
    width: auto;
    border: 0 none;
    background: #383030;
    border-radius: 3px;
    color: var(--color-heading);
    margin: 7.5px;
    font-weight: 600;
    transition: 0.4s;

    &.is-checked,
    &:hover {
      background: var(--color-secondary);
      color: var(--color-white);
    }
  }
}

.gallery-grid-item .gallery-grid .zoom-icon {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  transform: inherit !important;
  transition: 0.4s;
  opacity: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: 0;
  cursor: pointer;
  background: transparent;
}

.gallery-grid-item {
  display: block;
  border-radius: 5px;
  padding: 0 15px !important;
  .gallery-grid {
    position: relative;
    img {
      border-radius: 5px;
    }
    &::before {
      background: linear-gradient(
        180deg,
        rgba(82, 95, 225, 0.3) 10.75%,
        #525fe1 85%
      );
      border-radius: 5px;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.4s;
    }
    .zoom-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.4s;
      opacity: 0;
      i {
        color: var(--color-white);
        font-size: 25px;
      }
    }
    .hover-action {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 30px 40px;
      z-index: 1;
      transition: 0.4s;
      opacity: 0;
      @media #{$sm-layout} {
        padding: 20px 20px;
      }
      .hover-content {
        .hover-text {
          .title {
            color: var(--color-white);
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 0;
            @media #{$sm-layout} {
              font-size: 20px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
  &:hover {
    .gallery-grid {
      &::before {
        opacity: 1;
      }
      .zoom-icon {
        opacity: 1;
      }
      .hover-action {
        opacity: 1;
      }
    }
  }
}
/*--------------------------
    Pagination Styles  
----------------------------*/

.pagination {
  margin: -5px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    margin: 5px;
    list-style: none;
    a {
      width: 40px;
      height: 40px;
      background: var(--color-shade);
      display: inline-block;
      line-height: 40px;
      text-align: center;
      color: var(--color-body);
      transition: 0.4s;
      font-size: 16px;
      font-weight: 500;
      i {
        font-size: 22px;
        font-weight: 500;
      }
    }
    button {
      width: 50px;
      height: 50px;
      background: var(--color-shade);
      display: inline-block;
      line-height: 40px;
      border-radius: 5px;
      text-align: center;
      color: var(--color-body);
      transition: 0.4s;
      font-size: 16px;
      font-weight: 500;
      border: 0;
      border-color: transparent;
      i {
        font-size: 22px;
        font-weight: 500;
      }
    }
    &.active,
    &:hover {
      a,
      button {
        background: var(--color-primary);
        color: var(--color-white);
      }
    }
  }
}
