// Blog Search
.blog-search {
  position: relative;
  input {
    height: 60px;
    padding: 0 20px;
    color: var(--color-body);
    padding-right: 80px;
  }
  .search-button {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    border: 0 none;
    background: var(--color-primary-light);
    height: 47px;
    padding: 0 20px;
    border-radius: 3px;
    text-align: center;
    font-size: 20px;
    svg {
      color: var(--color-white);
      font-weight: 400;
    }
  }
}

.rbt-single-widget {
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid var(--color-border);
  padding-top: 0;

  &:first-child {
    margin-top: 0 !important;
  }

  input,
  select {
    border: var(--border-width) solid var(--color-border);
  }

  &.widget_search {
    padding: 0;
    border: 0 none;
    .title {
      margin: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 28px;
    color: #717173;
  }

  .title {
    line-height: 26px;
    position: relative;
    padding-bottom: 6px;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-white);
    background-color: var(--color-blackest);
    margin: -1px -22px 0;
    padding: 14px 24px;
    border-radius: 4px;
    a {
      color: var(--color-primary);
      &.rsswidget {
        img {
          height: 13px;
          margin-right: 3px;
        }
      }
    }
    img {
      vertical-align: 0px;
    }
  }

  // Blog List Style
  ul {
    padding-left: 0;
    margin-bottom: -10px;
    list-style: none;
    padding-right: 0;
    ul {
      margin-bottom: 0;
    }
    li {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 28px;
      color: #717173;
      a {
        transition: all 0.3s ease 0s;
        text-decoration: none;
        color: var(--color-heading);
        display: initial;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  &.widget_categories {
    background-color: var(--color-blackest);
    ul {
      li {
        a {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &.widget_recent_comments,
  &.widget_recent_entries,
  &.widget_archive,
  &.widget_categories,
  &.widget_meta,
  &.widget_pages,
  &.widget_nav_menu {
    ul {
      li {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0;
        margin-bottom: 0;
        color: var(--color-body);
        a {
          color: var(--color-body);
          @extend %transition;
          &:hover {
            color: var(--color-primary);
          }
        }
        span {
          @extend %transition;
          color: var(--color-body);
        }
        &:hover {
          a {
            span {
              color: var(--color-primary);
            }
          }
        }
        & + li {
          border-top: 1px solid var(--color-border);
        }
      }
    }
  }

  &.widget_recent_comments,
  &.widget_recent_entries,
  &.widget_archive,
  &.widget_categories,
  &.widget_meta,
  &.widget_pages {
    > ul {
      > li {
        margin-top: 0;
        margin-bottom: 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.widget_recent_entries {
    .inner {
      a {
        color: var(--color-white);
        margin-bottom: 4px;
      }
      span {
        color: var(--color-body);
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }
  }
}

.alignwide {
  margin-left: -220px;
  margin-right: -220px;
  max-width: 1400px;
  width: auto;
  @media #{$lg-layout} {
    margin-left: 0;
    margin-right: 0;
  }
  @media #{$md-layout} {
    margin-left: 0;
    margin-right: 0;
  }
  @media #{$sm-layout} {
    margin-left: 0;
    margin-right: 0;
  }
}

.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

// Tag Cloud Styles
.tagcloud,
.wp-block-tag-cloud {
  margin: -3px;
  padding-left: 0;
  a {
    background: transparent;
    display: inline-block;
    height: 40px;
    color: var(--color-body);
    font-size: 16px !important;
    line-height: 35px;
    text-decoration: none;
    margin: 3px;
    border: 2px solid var(--color-border);
    @extend %transition;
    padding: 0 20px;
    border-radius: 3px;
    &:hover {
      background: var(--color-primary);
      color: #ffffff;
      border-color: var(--color-primary);
    }
  }
}
