.split-style {
  .thumbnail {
    img {
      border-radius: 0;
      width: 100%;
    }
    &.image-left-content {
      img {
        border-radius: 10px 10px 10px 10px;
      }
    }
    &.image-right-content {
      img {
        border-radius: 10px 10px 10px 10px;
      }
    }
  }
  .split-inner {
    padding: 30px 115px;

    @media #{$lg-layout} {
      padding: 40px 80px;
    }
    @media #{$md-layout} {
      padding: 40px 30px;
    }
    @media #{$sm-layout} {
      padding: 40px 30px;
    }

    .title {
      font-size: 28px;
      line-height: 50px;
      color: var(--color-heading);
      font-weight: var(--p-bold);
      @media #{$sm-layout} {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .split-list {
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        color: var(--color-dark-alt);
      }
    }
  }
}

.no-radius
  .split-style
  .thumbnail.image-left-content
  .thumbnail.image-right-content
  img {
  border-radius: 0;
}

@media screen and (max-width: 1199px) {
  .split-no-swap-text {
    order: 1 !important;
  }
  .split-no-swap-image {
    order: 2 !important;
  }
}
@media #{$md-layout} {
  .split-no-swap-text {
    order: 1 !important;
  }
  .split-no-swap-image {
    order: 2 !important;
  }
}
@media #{$sm-layout} {
  .split-no-swap-text {
    order: 1 !important;
  }
  .split-no-swap-image {
    order: 2 !important;
  }
}
