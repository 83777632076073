.local-sales-table-headers {
  font-size: 20px;
  text-align: center;
  @media #{$md-layout} {
    font-size: 16px;
  }
  @media #{$sm-layout} {
    font-size: 12px;
  }
}

.sales-name,
.sales-title,
.sales-region,
.sales-phone,
.sales-email,
.filter-buttons {
  text-align: center;
  @media #{$md-layout} {
    font-size: 16px;
  }
  @media #{$sm-layout} {
    font-size: 12px;
  }
}

.sales-table-title {
  text-align: center;
  font-size: 45px;
  font-weight: 500;
  @media #{$md-layout} {
    font-size: 30px;
  }
  @media #{$sm-layout} {
    font-size: 25px;
  }
}
