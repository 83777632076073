/* ========= Forms Styles ========= */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

input,
select,
textarea {
  width: 100%;
  transition: 0.3s;
}

input {
  height: 40px;
  padding: 0 15px;
}

select {
  transition: 0.3s;
  height: 60px;
  line-height: 28px;
  padding: 10px 20px;
  outline: none;
  color: var(--color-body);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid var(--color-border);
  border-radius: var(--radius);
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 30px;
}

.select {
  transition: 0.3s;
  height: 60px;
  line-height: 28px;
  padding: 10px 20px;
  outline: none;
  color: var(--color-body);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0 none;
  border-radius: var(--radius);
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 30px;
  background-color: #f5f5f5;
  border-radius: 5px;
  max-width: 256px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
  font-size: 16px;
  font-weight: 500;
  height: auto;
  line-height: 28px;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 15px;
  outline: none;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
  color: var(--color-body);

  /* -- Placeholder -- */
  &::placeholder {
    color: var(--body-color);
    /* Firefox */
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--body-color);
    opacity: 1;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--body-color);
    opacity: 1;
  }

  &.p-holder__active {
    border-color: var(--color-primary);

    /* -- Placeholder -- */
    &::placeholder {
      color: var(--color-primary);
      /* Firefox */
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-primary);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--color-primary);
    }
  }

  &.p-holder__error {
    border-color: #f4282d;

    /* -- Placeholder -- */
    &::placeholder {
      color: #f4282d;
      /* Firefox */
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #f4282d;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #f4282d;
    }

    &:focus {
      border-color: #f4282d;
    }
  }

  &:focus {
    border-color: var(--color-primary);
  }
}

.input-active {
  @extend .p-holder__active;

  input {
    @extend .p-holder__active;
  }
}

.input-error {
  @extend .p-holder__error;

  input {
    @extend .p-holder__error;
  }
}

// Custom Checkbox and radio button
input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
  position: absolute;

  ~ label {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: var(--body-color);
    font-weight: 500;
    padding-left: 34px;
    cursor: pointer;
    margin-bottom: 0;
    &::before {
      content: " ";
      position: absolute;
      top: 1px;
      left: -3px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid var(--color-primary);
      border-radius: 2px;
      transition: all 0.3s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 1px;
      width: 12px;
      height: 8px;
      background-color: transparent;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      border-radius: 2px;
      transform: rotate(-45deg);
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &:checked {
    ~ label {
      &::before {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
      }
      &::after {
        opacity: 1;
      }
    }
  }
}

input[type="radio"] {
  ~ label {
    &::before {
      border-radius: 50%;
    }
    &::after {
      width: 8px;
      height: 8px;
      left: 3px;
      top: 7px;
      background: #fff;
      border-radius: 50%;
    }
  }
}

.form-group {
  margin-bottom: 20px;
  input {
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    height: 50px;
    font-size: 16px;
    @extend %transition;
    padding: 0 15px;

    &:focus {
      border-color: var(--color-primary);
    }
  }

  textarea {
    min-height: 193px;
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    resize: none;
    padding: 15px;
    font-size: 16px;
    @extend %transition;

    &:focus {
      border-color: var(--color-primary);
    }
  }
}

// Form Submit Styles
input[type="submit"] {
  width: auto;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 15px 20px;
  border-radius: 6px;
  display: block;
  font-weight: 500;
  transition: 0.3s;
  border: var(--border-width) solid var(--color-primary);
  background: var(--color-primary);
  color: #ffffff;
  height: 60px;
  margin-top: 30px;

  &:hover {
    color: #ffffff;
    -webkit-box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
    box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.contact-form--1 form input::-webkit-input-placeholder,
.contact-form--1 form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-body) !important;
}

.contact-form--1 form input::-moz-placeholder,
.contact-form--1 form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-body) !important;
}

.contact-form--1 form input:-ms-input-placeholder,
.contact-form--1 form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-body) !important;
}

.contact-form--1 form input:-moz-placeholder,
.contact-form--1 form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-body) !important;
}

.login-register-page-wrapper {
  .login-form-box {
    border: 1px solid #eeeeee;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    input[type="checkbox"] {
      width: auto;
    }
    .lost-password {
      color: var(--color-secondary);
    }
  }
}

.form-label-account {
  text-align: left !important;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.im-form {
  @media #{$md-layout} {
    order: 2;
  }
  @media #{$sm-layout} {
    order: 2;
  }
}

.im-form-img {
  @media #{$md-layout} {
    order: 1;
  }
  @media #{$sm-layout} {
    order: 1;
  }
}

.service-card-4 {
  height: 100%;
  .inner {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 40px 35px;
    text-align: center;
    transition: 0.4s;
    height: 100%;

    @media #{$lg-layout} {
      padding: 25px 14px;
    }

    @media #{$md-layout} {
      padding: 25px 14px;
    }

    @media #{$sm-layout} {
      padding: 25px 14px;
    }

    .icon {
      position: relative;
      i {
        display: block;
        color: var(--color-primary);
        font-size: 35px;
      }
      .subtitle {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.4s;
      }
    }
    .content {
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 15px;
        margin-top: 25px;
        a {
          transition: 0.4s;
          color: #231f40;
        }
      }
      .description {
        margin-bottom: 0;
        transition: 0.4s;
      }
    }
  }
}
