.slider-dot {
  &.slick-dot {
    .slick-dots {
      bottom: -45px;
      li {
        width: 48px;
        height: 48px;
      }
    }
  }
}

.height-850 {
  height: 850px;
  display: flex;
  align-items: center;

  @media #{$md-layout} {
    height: auto;
    padding: 150px 0;
  }

  @media #{$sm-layout} {
    height: auto;
    padding: 100px 0;
  }
}

.height-650 {
  height: 650px;
  display: flex;
  align-items: center;

  @media #{$md-layout} {
    height: 400px;
    padding: 150px 0;
  }

  @media #{$sm-layout} {
    height: 240px;
    padding: 100px 0;
  }
}

.height-750 {
  height: 750px;
  display: flex;
  align-items: center;

  @media #{$custom-lg-device-six} {
    height: auto;
    padding: 400px 0;
  }
  @media #{$custom-lg-device-eight} {
    height: auto;
    padding: 300px 0;
  }
  @media #{$lg-layout} {
    height: auto;
    padding: 200px 0;
  }
  @media #{$md-layout} {
    height: auto;
    padding: 150px 0;
  }

  @media #{$sm-layout} {
    height: auto;
    padding: 100px 0;
  }
}

.height-950 {
  height: 950px;
  display: flex;
  align-items: center;

  @media #{$md-layout} {
    height: auto;
    padding: 150px 0;
  }

  @media #{$sm-layout} {
    height: auto;
    padding: 150px 0;
  }
}

.display-one {
  font-size: 55px;
  @media #{$lg-layout} {
    font-size: 50px;
  }
  @media #{$md-layout} {
    font-size: 45px;
  }
  @media #{$sm-layout} {
    font-size: 40px;
  }
  @media #{$large-mobile} {
    font-size: 36px;
  }
}

.display-two {
  font-size: 85px;
  @media #{$lg-layout} {
    font-size: 70px;
  }
  @media #{$md-layout} {
    font-size: 65px;
  }

  @media #{$sm-layout} {
    font-size: 65px;
  }

  @media #{$large-mobile} {
    font-size: 46px;
  }
}

.bg-overlay {
  position: relative;

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    background-color: transparent;
    background-image: linear-gradient(90deg, #060606 0%, #06060600 90%);
    content: "";
  }
}

.bg-overlay-solid {
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    height: 100%;
    width: 40%;
    opacity: 1;
    top: 0;
    left: 0;
    content: "";
    background: var(--color-dark);
    z-index: -1;
  }

  &::after {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    opacity: 0.5;
    filter: blur(100px);
    left: auto;
    top: -250px;
    left: -250px;
    bottom: auto;
    background-image: linear-gradient(
      45deg,
      var(--color-secondary),
      var(--color-tertiary)
    );
    content: "";
    z-index: -1;
  }
}

.frame-image {
  padding: 20px;
  position: relative;
  right: 0;
  border-radius: 10px;
  transition: var(--transition);
  height: 100%;
  background-color: var(--color-blackest);

  img {
    width: 100%;
  }

  &:hover {
    transform: translateY(-5px);
  }
}

.slider-area {
  .inner {
    a,
    button {
      &.btn-default {
        &.btn-border {
          border-color: var(--color-border);
          color: var(--color-white);
        }
      }
    }
  }
}

.slider-style-1 {
  .inner {
    .subtitle {
      font-family: var(--font-primary);
      font-size: 18px;
      letter-spacing: 0.5px;
      display: block;
      margin-bottom: 18px;
      color: var(--color-body);

      @media #{$md-layout} {
        font-size: 16px;
        margin-bottom: 10px;
      }

      @media #{$sm-layout} {
        font-size: 16px;
        margin-bottom: 10px;
      }

      @media #{$large-mobile} {
        font-size: 14px;
      }
    }

    .title {
      font-family: var(--font-primary);
      margin-bottom: 25px;
      display: inline-block;

      span {
        display: inline-block;

        label {
          margin-bottom: 0;
        }
      }
    }

    .description {
      font-family: var(--font-primary);
      padding: 0 22%;
      font-size: 25px;
      line-height: 1.7;
      margin-bottom: 40px;
      color: var(--color-body);

      @media #{$lg-layout} {
        padding: 0 14%;
        font-size: 22px;
        margin-bottom: 35px;
      }

      @media #{$md-layout} {
        padding: 0 1%;
        font-size: 20px;
        margin-bottom: 30px;
      }

      @media #{$sm-layout} {
        padding: 0 1%;
        font-size: 20px;
        margin-bottom: 30px;
      }

      @media #{$large-mobile} {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }

    .button-group {
    }

    &.text-start {
      .description {
        padding: 0;
      }
    }
  }

  &.variation-default {
    .inner {
      padding-top: 80px;
    }
  }

  &.variation-2 {
    position: relative;
    .thumbnail {
      img {
        transform: scale(1.1);
      }
    }
  }
}

// Slider Style Two

.slider-style-2 {
  padding-top: 130px;

  @media #{$md-layout} {
    padding-top: 150px;
  }

  @media #{$sm-layout} {
    padding-top: 150px;
  }
  .inner {
    .subtitle {
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 18px;
      font-size: 14px;
      border-radius: 4px;
      margin-bottom: 12px;
      display: inline-block;
      padding: 7px 14px;
      background-color: var(--color-blackest);
      box-shadow: var(--shadow-lighter);
    }

    .title {
      font-family: var(--font-primary);
      margin-bottom: 25px;
      display: block;
      color: var(--color-white);
      &.display-one {
        @media #{$sm-layout} {
          font-size: 40px;
        }
      }
    }

    .list-icon {
      li {
        color: var(--color-body);
      }
    }
  }

  &.variation-2 {
    padding-top: 79px;
    @media #{$md-layout} {
      padding-top: 170px;
    }
    @media #{$sm-layout} {
      padding-top: 170px;
    }

    .inner {
      .title {
        font-weight: 500;
        font-size: 75px;
        margin-top: 0;
        @media #{$md-layout} {
          font-size: 54px;
        }
        @media #{$sm-layout} {
          font-size: 54px;
        }
        @media #{$large-mobile} {
          font-size: 40px;
        }
      }

      .tag-title {
        color: var(--color-secondary);
        margin: 0;
        letter-spacing: 1.5px;
        padding-left: 129px;
        position: relative;
        margin-top: 50px;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          width: 100px;
          height: 2px;
          background: var(--color-secondary);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.variation-3 {
    padding-top: 80px;
    @media #{$md-layout} {
      padding-top: 180px;
    }

    @media #{$sm-layout} {
      padding-top: 180px;
    }

    .inner {
      .title {
        font-family: var(--font-primary);
        margin-bottom: 25px;
        display: block;
        font-size: 80px;
        @media #{$md-layout} {
          font-size: 60px;
        }
        @media #{$sm-layout} {
          font-size: 50px;
          br {
            display: none;
          }
        }
      }

      .description {
        font-size: 20px;
        line-height: 37px;
        margin-bottom: 0;
        @media #{$sm-layout} {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }
}

// Slider Style Three

.slider-style-3 {
  @media #{$md-layout} {
    padding: 100px 0;
  }

  @media #{$sm-layout} {
    padding: 100px 0;
  }

  .inner {
    .title {
      font-family: var(--font-primary);
      margin-bottom: 25px;
      display: block;
      font-size: 130px;
      line-height: 1.1;

      @media #{$lg-layout} {
        font-size: 100px;
      }

      @media #{$md-layout} {
        font-size: 80px;
      }

      @media #{$sm-layout} {
        margin-bottom: 20px;
        font-size: 50px;
      }
    }

    .description {
      font-size: 22px;
      line-height: 43px;

      @media #{$md-layout} {
        font-size: 20px;
        line-height: 38px;
      }

      @media #{$sm-layout} {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .thumbnail {
    position: relative;
    z-index: 2;

    &::after {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: calc(100% - 130px);
      content: "";
      left: 50%;
      bottom: 0;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background: var(--color-blackest);
      box-shadow: var(--shadow-1);
      border-radius: 6px;
    }
  }
}

// Slider Style 4
.slider-style-4 {
  .inner {
    @media #{$sm-layout} {
      padding-top: 20px;
    }
    > * {
      animation-name: fadeOutUp;
      animation-fill-mode: both;
      animation-duration: 1s;
    }
    .title {
      font-family: var(--font-primary);
      margin-bottom: 25px;
      display: block;
      font-size: 70px;
      line-height: 1.1;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: var(--color-white);

      @media #{$lg-layout} {
        font-size: 60px;
      }

      @media #{$md-layout} {
        font-size: 50px;
      }

      @media #{$sm-layout} {
        font-size: 20px;
      }

      @media #{$large-mobile} {
        font-size: 20px;
      }
    }

    .description {
      font-size: 22px;
      line-height: 43px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: var(--color-body);
      @media #{$sm-layout} {
        font-size: 20px;
        line-height: 35px;
      }
    }

    .button-group {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  &.variation-2 {
    .inner {
      .title {
        font-size: 70px;
        @media #{$sm-layout} {
          font-size: 42px;
        }
      }
    }
    .bg-overlay {
      &::before {
        background: #000000;
        content: "";
        opacity: 0.5;
        background-image: none;
      }
    }
  }
  &.variation-3 {
    .inner {
      .title {
        font-size: 70px;
      }
    }
    .bg-overlay {
      &::before {
        background: #000000;
        content: "";
        opacity: 0.5;
        background-image: none;
      }
    }
  }
}

// Slider Style 5
.slider-style-5 {
  .inner {
    .subtitle {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.5px;
      position: relative;
      padding-right: 20px;
      display: inline-block;
      color: var(--color-white);

      &::after {
        position: absolute;
        content: "";
        width: 120px;
        height: 2px;
        background: var(--color-pink);
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .title {
      font-family: var(--font-primary);
      margin-bottom: 25px;
      display: block;
      font-size: 64px;
      line-height: 1.4;
      color: var(--color-white);

      @media #{$sm-layout} {
        font-size: 40px;
      }
    }
  }
}

// Slider Style 5
.slider-style-6 {
  background-color: var(--color-blackest);

  @media #{$md-layout} {
    overflow: hidden;
    padding: 100px 0;
    padding-top: 170px;
  }

  &.shape-right {
    position: relative;
    z-index: 2;
    overflow: hidden;
    .shape-image {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0.5;
      width: auto;
      @media #{$md-layout} {
        display: none;
      }
      @media #{$sm-layout} {
        display: none;
      }
    }
  }

  &.shape-left {
    position: relative;
    .inner {
      position: relative;
      z-index: 2;
    }
    .shape-image {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.5;
      transform: rotate(180deg);
      height: 100%;
      bottom: 0;
      width: auto;
      @media #{$md-layout} {
        display: none;
      }

      @media #{$sm-layout} {
        display: none;
      }
    }
  }

  &.inheader-not-transparent {
    @media #{$md-layout} {
      padding-top: 100px;
    }
  }

  .inner {
    .title {
      font-family: var(--font-primary);
      margin-bottom: 25px;
      display: block;
      font-size: 80px;
      line-height: 1.1;

      @media #{$lg-layout} {
        font-size: 62px;
      }

      @media #{$md-layout} {
        font-size: 60px;
      }

      @media #{$sm-layout} {
        font-size: 45px;
      }
      br {
        @media #{$sm-layout} {
          display: none;
        }
      }
    }

    .description {
      font-size: 22px;
      line-height: 43px;

      @media #{$md-layout} {
        font-size: 20px;
        line-height: 38px;
      }

      @media #{$sm-layout} {
        font-size: 18px;
        line-height: 30px;
      }
    }

    a {
      &.btn-default {
        &.btn-border {
          border-color: var(--color-border);
          color: var(--color-white);
        }
      }
    }
  }
}

.slider-perosonal-portfolio {
  &.height-750 {
    @media #{$md-layout} {
      padding-top: 180px;
    }
    @media #{$sm-layout} {
      padding-top: 180px;
    }
  }
  .inner {
    margin-top: -100px;
    @media #{$md-layout} {
      margin-top: 0;
    }
    @media #{$sm-layout} {
      margin-top: 0;
    }
    .title {
      font-size: 100px;
      color: var(--color-white);
      @media #{$md-layout} {
        font-size: 58px;
      }
      @media #{$sm-layout} {
        font-size: 46px;
      }
    }

    a,
    button {
      &.btn-default {
        &.btn-border {
          border-color: var(--color-border);
          color: var(--color-white);
        }
      }
    }
  }
}

// Slider Style 7
.slider-style-7 {
  .inner {
    .title {
      font-size: 100px;
      @media #{$lg-layout} {
        font-size: 60px;
      }
      @media #{$md-layout} {
        font-size: 50px;
      }
      @media #{$sm-layout} {
        br {
          display: none;
        }
        font-size: 32px;
      }
    }
  }
}

// Slider Style 8

.slider-style-8 {
  justify-content: center;
  .inner {
    .title {
      font-size: 100px;
      line-height: 1.1;
      @media #{$md-layout} {
        font-size: 60px;
      }
      @media #{$sm-layout} {
        font-size: 46px;
      }
    }
    .description {
      font-size: 22px;
      line-height: 43px;
      @media #{$sm-layout} {
        font-size: 20px;
        line-height: 38px;
      }
      br {
        @media #{$md-layout} {
          display: none;
        }
        @media #{$sm-layout} {
          display: none;
        }
      }
    }
  }
}
// Slider Animation
.slider-style-4 {
  .slick-active {
    .inner {
      > * {
        animation-name: fadeInUp;
      }

      .title {
        animation-delay: 0.5s;
      }

      .description {
        animation-delay: 1s;
      }

      .button-group {
        animation-delay: 1.2s;
      }
    }
  }
}

// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//     .theme-gradient {
//         color:#f9004d;
//         background: none !important;
//     }
// }

.banner-style-4 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  .home-four-banner-wrapper {
    min-height: 870px;
    @media #{$smlg-device} {
      min-height: auto;
      width: 100%;
      width: 100%;
      padding: 120px 0 100px;
    }
    @media #{$small-tablet} {
      padding: 70px 0 150px;
    }
  }
  .home-four-banner-wrapper-mib {
    min-height: 650px;
    @media #{$smlg-device} {
      min-height: auto;
      width: 100%;
      width: 100%;
      padding: 120px 0 100px;
    }
    @media #{$small-tablet} {
      padding: 70px 0 150px;
    }
  }
  .banner-image {
    position: relative;
    padding: 35px;
    left: 115px;
    @media #{$lg-layout} {
      padding: 25px;
      left: 10px;
      transform: scale(0.9);
    }
    @media #{$md-layout} {
      left: 0;
      width: 74%;
      margin: 0 auto;
    }
    @media #{$sm-layout} {
      left: 0;
      margin: 0 auto;
      padding: 30px;
    }
    @media #{$large-mobile} {
      padding: 20px;
    }
    .thumbnail {
      img {
        width: 500px;
        max-height: 500px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    .round-images {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s;
    }
    .learner-badge {
      background: #ffffff;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      padding: 10px;
      width: 160px;
      height: 160px;
      border-radius: 100%;
      position: absolute;
      left: -80px;
      top: 25%;
      @media #{$large-mobile} {
        display: none;
      }
      .badge-inner {
        background: #ffa41b;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          display: block;
          color: #ffffff;
          font-weight: 600;

          &.viewer {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
          }
        }
      }
    }
  }

  .content {
    .pre-title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-primary);
      display: block;
      margin-bottom: 5px;
    }
    .title {
      font-weight: 800;
      font-size: 72px;
      line-height: 1.25;
      margin-bottom: 25px;
      @media #{$lg-layout} {
        font-size: 50px;
      }
      @media #{$md-layout} {
        font-size: 40px;
      }
      @media #{$sm-layout} {
        font-size: 40px;
      }
    }

    .description {
      margin-bottom: 60px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      @media #{$md-layout} {
        margin-bottom: 30px;
      }
      @media #{$sm-layout} {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .shape-image {
    position: absolute;
    z-index: -1;

    &.shape-image-1 {
      left: -110px;
      top: -50px;
    }

    &.shape-image-2 {
      right: 48%;
      top: -32px;
    }

    &.shape-image-3 {
      right: -210px;
      top: 10px;
    }

    &.shape-image-4 {
      right: 360px;
      bottom: -90px;
    }

    &.shape-image-5 {
      left: 463px;
      bottom: 270px;
    }

    &.shape-image-6 {
      left: 150px;
      bottom: -100px;
    }
    &.shape-image-left {
      left: 0;
      bottom: 8px;
    }
    &.shape-image-right {
      right: 0;
      bottom: 8px;
    }
  }

  .social-text-share {
    @extend %liststyle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -175px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    @media #{$laptop-device} {
      left: -105px;
    }
    @media #{$custom-laptop-device} {
      left: -70px;
    }
    @media #{$smlg-device} {
      position: absolute;
      top: inherit;
      transform: none;
      left: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      bottom: -40px;
      margin: 0 -20px;
    }
    @media #{$small-tablet} {
      bottom: -65px;
    }

    li {
      margin: 0;
      a {
        display: inline-block;
        transform: rotate(-90deg);
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 20px 0;
        position: relative;
        @media #{$smlg-device} {
          transform: none;
          margin: 0px 20px;
        }
        &::before {
          position: absolute;
          content: "";
          background: var(--color-primary);
          bottom: 0;
          width: 0;
          opacity: 0;
          left: 0;
          transition: 0.4s;
          height: 2px;
        }
        &:hover {
          &::before {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
}
/*-------------------------
    Contact Us Area  
---------------------------*/

.contact-address-card-1 {
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 50px 20px;
  text-align: center;
  transition: 0.4s;
  .inner {
    .icon {
      background: rgba(82, 95, 225, 0.1);
      width: 50px;
      height: 50px;
      margin: 0 auto;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s;
      i {
        color: var(--color-primary);
        font-size: 24px;
        transition: 0.4s;
      }
    }
    .content {
      .title {
        font-weight: 700;
        margin-top: 25px;
        margin-bottom: 15px;
        transition: 0.4s;
      }
      p {
        margin-bottom: 0;
        transition: 0.4s;
        a {
          color: var(--color-body);
          transition: 0.4s;
          &:hover {
            color: #e23e96;
          }
        }
      }
    }
  }

  &.phone {
    .inner {
      .icon {
        background: rgba(251, 124, 86, 0.1);
        i {
          color: var(--color-secondary);
        }
      }
    }
  }

  &.email {
    .inner {
      .icon {
        background: rgba(255, 164, 27, 0.1);
        i {
          color: #e23e96;
        }
      }
    }
  }

  &:hover {
    background: #059dff;
    transform: translateY(-15px);
    .inner {
      .icon {
        background: var(--color-white);
        i {
          color: #e23e96;
        }
      }
      .content {
        .title {
          color: var(--color-white);
        }
        p {
          color: var(--color-white);
          a {
            color: var(--color-white);
          }
        }
      }
    }
  }
}

.contact-address-card-2 {
  background: #ffffff;
  box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 70px 40px;
  text-align: center;
  transition: 0.4s;
  @media #{$lg-layout} {
    padding: 60px 20px;
  }
  @media #{$md-layout} {
    padding: 60px 20px;
  }

  .inner {
    .icon {
      margin-bottom: 45px;
      img {
      }
    }
    .content {
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 26px;
        .subtitle {
          font-weight: 600;
          color: var(--color-heading);
        }
        .text {
          font-weight: 500;
        }
      }
    }
  }
  &:hover {
    transform: translateY(-15px);
  }
}

.contact-address-bottom-shape {
  position: relative;
  z-index: 1;
  .bg-shape-image {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

/*------------------------------
  Contact Form Style One 
-------------------------------*/
.rwt-dynamic-form {
  .form-group {
    input {
      background: #f5f5f5;
      border-radius: 5px;
      padding: 0 30px;
      border: 1px solid transparent;
      transition: 0.4s;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      height: 60px;
      &:focus {
        border-color: var(--color-primary);
      }
    }
    select {
      background: #f5f5f5;
      border-radius: 5px;
      padding: 0 30px;
      border: 1px solid transparent;
      transition: 0.4s;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      height: 60px;
      &:focus {
        border-color: var(--color-primary);
      }
    }
    textarea {
      background: #f5f5f5;
      padding: 17px 30px;
      min-height: 180px;
      border: 0 none;
      border: 1px solid transparent;
      transition: 0.4s;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      border-radius: 5px;
      &:focus {
        border-color: var(--color-primary);
      }
    }
  }
  .success-message {
    margin-top: 20px;
    color: #019267;
  }
}

/*---------------------------
  About Me 
----------------------------*/
.about-me-1 {
  .thumbnail {
    position: relative;
    border-radius: 5px;
    &::before {
      background: linear-gradient(
        360deg,
        #2d284e 0%,
        rgba(45, 40, 78, 0) 70.5%
      );
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    img {
      border-radius: 5px;
    }
    .content-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 50px 20px;
      @media #{$sm-layout} {
        padding: 20px 20px;
      }
      .title {
        margin-bottom: 0;
        color: var(--color-white);
        font-size: 32px;
        line-height: 44px;
        @media #{$sm-layout} {
          font-size: 22px;
          line-height: 32px;
        }
      }
    }
  }
}

.google-map {
  &.alignwide {
    margin-left: -105px;
    margin-right: -105px;
    min-width: 1170px;
    width: auto;
    @media #{$custom-lg-device} {
      margin-left: 0;
      margin-right: 0;
      min-width: auto;
    }
    iframe {
      width: 100%;
    }
  }
}
.content-im {
  .pre-title {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-primary);
    display: block;
    margin-bottom: 5px;
  }
  .title {
    font-weight: 800;
    font-size: 72px;
    line-height: 1.25;
    margin-bottom: 25px;
    @media #{$lg-layout} {
      font-size: 50px;
    }
    @media #{$md-layout} {
      font-size: 40px;
    }
    @media #{$sm-layout} {
      font-size: 40px;
    }
  }

  .description {
    margin-bottom: 60px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    @media #{$md-layout} {
      margin-bottom: 30px;
    }
    @media #{$sm-layout} {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 28px;
    }
  }
}
