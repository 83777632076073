.social-text-share {
  @extend %liststyle;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media #{$laptop-device} {
    left: 105px;
  }
  @media #{$custom-laptop-device} {
    left: 70px;
  }
  @media #{$smlg-device} {
    position: absolute;
    top: inherit;
    transform: none;
    left: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 40px;
    margin: 0 -20px;
  }
  @media #{$small-tablet} {
    bottom: 25px;
  }

  li {
    margin: 0;
    a {
      display: inline-block;
      font-weight: 500;
      font-size: 40px;
      line-height: 22px;
      margin: 20px 0;
      position: relative;
      @media #{$smlg-device} {
        transform: none;
        margin: 0px 20px;
        font-size: 30px;
      }
      &::before {
        position: absolute;
        content: "";
        background: var(--color-primary);
        bottom: 0;
        width: 0;
        opacity: 0;
        left: 0;
        transition: 0.4s;
        height: 2px;
      }
    }
  }
}
