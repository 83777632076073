.post-scale {
  overflow: hidden;
  @extend %radius;
  img {
    transition: 0.5s;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

@keyframes customOne {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes customTwo {
  0% {
    transform: (translate(0px, 0px));
  }

  50% {
    transform: (translate(100px, 0px));
  }

  100% {
    transform: (translate(50px, 50px));
  }
}

.customOne {
  animation: customOne 2s infinite;
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }

  to {
    margin-top: 0;
  }
}

@-webkit-keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.slideFadeInUp {
  -webkit-animation-name: slideFadeInUp;
  animation-name: slideFadeInUp;
}

@-webkit-keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes scrollDown {
  0% {
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}
