/*===========================
    3. HERO css 
===========================*/
.mgga-hero-area {
  padding-top: 200px;
  padding-bottom: 110px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.mgga-hero-area.mgga-hero-3-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 0px;
  overflow: visible;
  z-index: 15;
}
.mgga-hero-area.mgga-hero-3-area .mgga-hero-content .mgga-title {
  color: #fff;
  font-size: 70px;
  line-height: 80px;
  padding: 0 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-area.mgga-hero-3-area .mgga-hero-content .mgga-title {
    font-size: 54px;
    line-height: 64px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-area.mgga-hero-3-area .mgga-hero-content .mgga-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area .mgga-hero-content .mgga-title {
    font-size: 36px;
    line-height: 46px;
    padding: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area .mgga-hero-content .mgga-title {
    font-size: 46px;
    line-height: 56px;
    padding: 0;
  }
}
.mgga-hero-area.mgga-hero-3-area .mgga-hero-content p {
  color: #fff;
  opacity: 0.5;
  font-size: 18px;
  line-height: 28px;
}
.mgga-hero-area.mgga-hero-3-area .mgga-hero-content .hero-btns {
  margin-top: 35px;
}
.mgga-hero-area.mgga-hero-3-area .mgga-hero-content .hero-btns .main-btn {
  border-color: #ff6b58;
  color: #ff6b58;
  background: transparent;
  margin-right: 14px;
}
.mgga-hero-area.mgga-hero-3-area
  .mgga-hero-content
  .hero-btns
  .mgga-video-popup {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area
    .mgga-hero-content
    .hero-btns
    .mgga-video-popup {
    margin-top: 20px;
  }
}
.mgga-hero-area.mgga-hero-3-area
  .mgga-hero-content
  .hero-btns
  .mgga-video-popup
  i {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  margin-right: 10px;
}
.mgga-hero-area.mgga-hero-3-area .mgga-hero-content .thumb {
  margin-bottom: -210px;
}
@media (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area .mgga-hero-content .thumb {
    display: none;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area .mgga-hero-content .thumb {
    display: block;
    margin-bottom: -210px;
  }
}
.mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area {
  background-color: #eef1f6;
  padding-top: 280px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area {
    padding-top: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area {
    padding-top: 160px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area {
    padding-top: 120px;
    padding-bottom: 0px;
  }
}
.mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area .mgga-hero-content .thumb {
  margin-bottom: -110px;
}
.mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
  .mgga-hero-content
  .mgga-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
    .mgga-hero-content
    .mgga-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
    .mgga-hero-content
    .mgga-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
    .mgga-hero-content
    .mgga-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
    .mgga-hero-content
    .mgga-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
  .mgga-hero-content
  .hero-btns
  .mgga-video-popup
  i {
  border-color: rgba(255, 255, 255, 0.2);
}
.mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
  .mgga-hero-content
  .hero-btns
  .main-btn {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.mgga-hero-area.mgga-hero-3-area.mgga-hero-5-area
  .mgga-hero-content
  .hero-btns
  .main-btn:hover {
  background: #f84a6e;
  color: #fff;
}
.mgga-hero-area .hero-shape-1 {
  position: absolute;
  left: 0;
  top: 250px;
  z-index: -1;
  animation: linear 20s animationFramesOne infinite;
}
.mgga-hero-area .hero-shape-2 {
  position: absolute;
  left: 80px;
  z-index: -1;
  top: 480px;
  animation: linear 25s animationFramesThree infinite;
}
.mgga-hero-area .hero-shape-3 {
  position: absolute;
  bottom: 120px;
  right: 320px;
  z-index: -1;
  animation: linear 25s animationFramesFive infinite;
}
.mgga-hero-area.mgga-hero-4-area {
  background: #f9f9f9;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 6% 100%;
  padding-top: 140px;
  padding-bottom: 60px;
}
.mgga-hero-area.mgga-hero-6-area {
}
.mgga-hero-area.mgga-hero-7-area {
}
.mgga-hero-area.mgga-hero-8-area {
  position: relative;
  z-index: 10;
}
.mgga-hero-area.mgga-hero-8-area .home-8-shape-1 {
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: -1;
}
.mgga-hero-area.mgga-hero-8-area .home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.mgga-hero-area.mgga-hero-8-area .home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}
.mgga-hero-area.mgga-hero-8-area .home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}
.mgga-hero-area.mgga-hero-8-area .mgga-hero-content-8 .mgga-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #fb2d4e 25%, #4e88ff 75%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-area.mgga-hero-8-area .mgga-hero-content-8 .mgga-title {
    font-size: 56px;
    line-height: 66px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-area.mgga-hero-8-area .mgga-hero-content-8 .mgga-title {
    font-size: 42px;
    line-height: 66px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-area.mgga-hero-8-area .mgga-hero-content-8 .mgga-title {
    font-size: 32px;
    line-height: 44px;
  }
}

.mgga-hero-thumb-6 .thumb {
  position: relative;
  z-index: 10;
}
.mgga-hero-thumb-6 .thumb .back-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-content {
    margin-bottom: 50px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .mgga-hero-content {
    margin-bottom: 50px;
    text-align: center;
  }
}
.mgga-hero-content > span {
  color: #2b70fa;
  font-size: 14px;
  font-weight: 500;
}
.mgga-hero-content .mgga-title {
  font-size: 60px;
  line-height: 70px;
  margin-top: 10px;
  margin-bottom: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-content .mgga-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-content .mgga-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-content .mgga-title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-content .mgga-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.mgga-hero-content p {
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 767px) {
  .mgga-hero-content p {
    font-size: 15px;
    line-height: 26px;
  }
}
.mgga-hero-content ul {
  margin: 41px 0 0;
  padding: 0;
  list-style-type: none;
}
.mgga-hero-content ul li {
  display: inline-block;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .mgga-hero-content ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-content ul li {
    margin-bottom: 0px;
  }
}
.mgga-hero-content ul li a {
  background: #0e1133;
  color: #fff;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 2px solid #0e1133;
}
.mgga-hero-content ul li a i {
  padding-right: 6px;
}
.mgga-hero-content ul li a:hover {
  background: transparent;
  color: #0e1133;
}
.mgga-hero-content ul li a.item-2 {
  background: transparent;
  color: #0e1133;
}
.mgga-hero-content ul li a.item-2:hover {
  background: #0e1133;
  color: #fff;
}
.mgga-hero-content.mgga-hero-content-4 {
  padding-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-content.mgga-hero-content-4 {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .mgga-hero-content.mgga-hero-content-4 {
    padding-right: 0;
  }
}
.mgga-hero-content.mgga-hero-content-4 > span {
  color: #801f82;
}
.mgga-hero-content.mgga-hero-content-4 .mgga-title {
  font-size: 70px;
  font-weight: 700;
  line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-content.mgga-hero-content-4 .mgga-title {
    font-size: 60px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-content.mgga-hero-content-4 .mgga-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-content.mgga-hero-content-4 .mgga-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-content.mgga-hero-content-4 .mgga-title {
    font-size: 40px;
    line-height: 50px;
  }
}
.mgga-hero-content.mgga-hero-content-4 a.main-btn {
  border: 2px solid #801f82;
  color: #801f82;
  background: #fff;
  line-height: 55px;
  padding: 0 40px;
  font-size: 16px;
  margin-top: 40px;
}
.mgga-hero-content.mgga-hero-content-4 a.main-btn:hover {
  background: #801f82;
  color: #fff;
}
.mgga-hero-content.mgga-hero-content-6 > span {
  color: #9b2cfa;
}
.mgga-hero-content.mgga-hero-content-6 .mgga-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-content.mgga-hero-content-6 .mgga-title {
    font-size: 60px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-content.mgga-hero-content-6 .mgga-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-content.mgga-hero-content-6 .mgga-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.mgga-hero-content.mgga-hero-content-6 ul li a {
  background: #9b2cfa;
  border-color: #9b2cfa;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mgga-hero-content.mgga-hero-content-6 ul li a span {
  line-height: 20px;
}
.mgga-hero-content.mgga-hero-content-6 ul li a span span {
  font-size: 16px;
  font-weight: 700;
  display: block;
  line-height: 20px;
}
.mgga-hero-content.mgga-hero-content-6 ul li a i {
  font-size: 30px;
}
.mgga-hero-content.mgga-hero-content-6 ul li a:hover {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}
.mgga-hero-content.mgga-hero-content-6 ul li a.item-2 {
  background: #fff;
  border-color: #fff;
}
.mgga-hero-content.mgga-hero-content-6 ul li a.item-2:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
  color: #fff;
}
.mgga-hero-content.mgga-hero-content-6.mgga-hero-content-7 .mgga-title {
  color: #fff;
}
.mgga-hero-content.mgga-hero-content-6.mgga-hero-content-7 p {
  color: #fff;
  opacity: 0.5;
}
.mgga-hero-content.mgga-hero-content-6.mgga-hero-content-7 ul li a {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}
.mgga-hero-content.mgga-hero-content-6.mgga-hero-content-7 ul li a:hover {
  background: transparent;
  border-color: #54386a;
  color: #fff;
}
.mgga-hero-content.mgga-hero-content-6.mgga-hero-content-7 ul li a.item-2 {
  background: transparent;
  color: #fff;
  border-color: #54386a;
}
.mgga-hero-content.mgga-hero-content-6.mgga-hero-content-7
  ul
  li
  a.item-2:hover {
  border-color: #fff;
  background: #fff;
  color: #0e1133;
}

.mgga-hero-thumb {
  position: relative;
  z-index: 10;
  text-align: center;
}
.mgga-hero-thumb .thumb-2 {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 30px 30px 0px rgba(68, 1, 15, 0.2);
  border-radius: 10px;
}
/* .mgga-hero-thumb::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 500px;
  width: 500px;
  transform: translate(-50%, -50%);
  background: #ff3e66;
  border-radius: 50%;
  z-index: -1;
} */
.mgga-hero-thumb.mgga-hero-thumb-4::before {
  height: 160px;
  width: 160px;
  top: 200px;
  left: 70px;
  background: #801f82;
  transform: translate(0%, 0%);
}
.mgga-hero-thumb.mgga-hero-thumb-4 .hero-dot {
  position: absolute;
  right: 70px;
  bottom: 115px;
  z-index: -1;
}

.mgga-hero-area-2 {
  padding-top: 290px;
  padding-bottom: 170px;
  background: #eef1f6;
  position: relative;
  overflow: hidden;
  z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-area-2 {
    padding-top: 190px;
    padding-bottom: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-area-2 {
    padding-top: 190px;
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-area-2 {
    padding-top: 190px;
    padding-bottom: 150px;
  }
}
.mgga-hero-area-2 .mgga-hero-thumb-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 48%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .mgga-hero-area-2 .mgga-hero-thumb-3 {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-area-2 .mgga-hero-thumb-3 {
    width: 40%;
  }
}
@media (max-width: 767px) {
  .mgga-hero-area-2 .mgga-hero-thumb-3 {
    width: 36%;
  }
}
.mgga-hero-area-2 .hero-shape-1 {
  position: absolute;
  top: 250px;
  left: 130px;
  animation: linear 20s animationFramesOne infinite;
  z-index: -1;
}
.mgga-hero-area-2 .hero-shape-2 {
  position: absolute;
  bottom: 290px;
  left: 40px;
  animation: linear 20s animationFramesFour infinite;
  z-index: -1;
}
.mgga-hero-area-2 .hero-shape-3 {
  position: absolute;
  top: 480px;
  right: 240px;
  animation: linear 20s animationFramesFive infinite;
  z-index: -1;
}
.mgga-hero-area-2 .hero-shape-4 {
  position: absolute;
  top: 210px;
  right: 0px;
  animation: linear 20s animationFramesThree infinite;
  z-index: -1;
}

.mgga-hero-content-2 > span {
  font-size: 16px;
  font-weight: 500;
  color: #ff3e66;
  position: relative;
}
.mgga-hero-content-2 > span::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.mgga-hero-content-2 .mgga-title {
  font-size: 64px;
  line-height: 70px;
  font-weight: 400;
  margin-top: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-hero-content-2 .mgga-title {
    font-size: 50px;
    line-height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-hero-content-2 .mgga-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .mgga-hero-content-2 .mgga-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-hero-content-2 .mgga-title {
    font-size: 40px;
    line-height: 50px;
  }
}
.mgga-hero-content-2 .mgga-title span {
  font-weight: 900;
}
.mgga-hero-content-2 p {
  font-size: 18px;
  color: #0e1133;
  margin-top: 22px;
}
.mgga-hero-content-2 .input-box {
  position: relative;
  margin-top: 40px;
  width: 400px;
}
@media (max-width: 767px) {
  .mgga-hero-content-2 .input-box {
    width: 100%;
  }
}
.mgga-hero-content-2 .input-box > i {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #c3c3cc;
}
.mgga-hero-content-2 .input-box input {
  width: 100%;
  height: 60px;
  border-radius: 40px;
  border: 0;
  border-left: 3px solid #ff3e66;
  padding-left: 55px;
  box-shadow: -3px 0px 0px 0px rgba(255, 62, 102, 0.004),
    0px 30px 30px 0px rgba(14, 17, 51, 0.1);
}
.mgga-hero-content-2 .input-box button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 0;
  color: #ff3e66;
}
.mgga-hero-content-2 .hero-users {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.mgga-hero-content-2 .hero-users > span {
  margin-left: 20px;
  font-size: 26px;
  font-weight: 900;
  color: #0e1133;
  width: 60px;
  display: inline-block;
  line-height: 16px;
}
.mgga-hero-content-2 .hero-users > span span {
  font-size: 13px;
  font-weight: 500;
  color: #505056;
  display: inline-block;
  line-height: 13px;
}

.mgga-page-title-area {
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.mgga-page-title-area.mgga-page-service-title-area {
  height: 650px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-page-title-area.mgga-page-service-title-area {
    height: 500px;
  }
}
@media (max-width: 767px) {
  .mgga-page-title-area.mgga-page-service-title-area {
    height: 400px;
  }
}
.mgga-page-title-area.mgga-page-service-title-area
  .mgga-page-title-item
  .thumb {
  right: 110px;
  width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-page-title-area.mgga-page-service-title-area
    .mgga-page-title-item
    .thumb {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-page-title-area.mgga-page-service-title-area
    .mgga-page-title-item
    .thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .mgga-page-title-area.mgga-page-service-title-area
    .mgga-page-title-item
    .thumb {
    display: none;
  }
}

.mgga-page-title-item {
  margin-top: 80px;
  position: relative;
}
.mgga-page-title-item .thumb {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 160px;
}
@media (max-width: 767px) {
  .mgga-page-title-item .thumb {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-page-title-item .thumb {
    display: block;
  }
}
.mgga-page-title-item span {
  color: #fff;
  font-weight: 500;
  opacity: 0.7;
  padding-bottom: 14px;
}
.mgga-page-title-item .title {
  color: #fff;
  font-size: 44px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .mgga-page-title-item .title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-page-title-item .title {
    font-size: 32px;
  }
}
.mgga-page-title-item nav {
  display: inline-block;
  margin-top: 20px;
}
.mgga-page-title-item nav ol {
  background: transparent;
  padding: 0;
}
.mgga-page-title-item nav ol li a {
  color: #fff;
  opacity: 0.8;
}
.mgga-page-title-item nav ol li.active {
  color: #fff;
  opacity: 0.8;
}
.mgga-features-area {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.mgga-features-area .features-shape-1 {
  position: absolute;
  left: 560px;
  top: 130px;
  animation: animationFramesOne 20s linear infinite;
  z-index: -1;
}
.mgga-features-area .features-shape-2 {
  position: absolute;
  left: 500px;
  bottom: 0px;
  animation: animationFramesThree 20s linear infinite;
  z-index: -1;
}
.mgga-features-area .features-shape-3 {
  position: absolute;
  right: 270px;
  bottom: 0;
  animation: animationFramesFive 20s linear infinite;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-features-tabs-btn .nav {
    flex-direction: row !important;
    justify-content: center;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .mgga-features-tabs-btn .nav {
    flex-direction: row !important;
    justify-content: center;
    margin-bottom: 40px;
  }
}
.mgga-features-tabs-btn .nav a {
  border-right: 3px solid #e8eaef;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #505056;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-features-tabs-btn .nav a {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .mgga-features-tabs-btn .nav a {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
  }
}
.mgga-features-tabs-btn .nav a i {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  background: #f1f2f7;
  color: #63636a;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.mgga-features-tabs-btn .nav a.active {
  border-right-color: #2b70fa;
  background: transparent;
  color: #2b70fa;
}
.mgga-features-tabs-btn .nav a.active i {
  color: #fff;
  background: #2b70fa;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-features-content {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .mgga-features-content {
    padding-top: 30px;
  }
}
.mgga-features-content span {
  font-size: 14px;
  font-weight: 500;
  color: #2b70fa;
  margin-bottom: 8px;
}
.mgga-features-content .title {
  font-size: 44px;
  line-height: 54px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-features-content .title {
    font-size: 36px;
    line-height: 44px;
  }
}
@media (max-width: 767px) {
  .mgga-features-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}
.mgga-features-content p {
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 30px;
}

.mgga-features-area-2 {
  background: #0e1133;
  position: relative;
  overflow: hidden;
  z-index: 10;
}
.mgga-features-area-2 .features-shape-1 {
  position: absolute;
  left: 240px;
  top: 240px;
  z-index: -1;
  animation: linear 20s animationFramesThree infinite;
}
.mgga-features-area-2 .features-shape-2 {
  position: absolute;
  right: 190px;
  top: 160px;
  z-index: -1;
  animation: linear 20s animationFramesFive infinite;
}
.mgga-features-area-2 .features-shape-3 {
  position: absolute;
  right: 160px;
  bottom: 260px;
  z-index: -1;
  animation: linear 20s animationFramesOne infinite;
}
.mgga-features-area-2.mgga-features-area-5 {
  background: #fff;
}

.mgga-features-box-item {
  border: 1px solid #202234;
  padding: 25px 30px 30px 70px;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 20px;
  margin-left: 100px;
  margin-right: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-features-box-item {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-features-box-item {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 767px) {
  .mgga-features-box-item {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 60px;
  }
}
.mgga-features-box-item .title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-features-box-item .title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .mgga-features-box-item .title {
    font-size: 20px;
  }
}
.mgga-features-box-item .title::before {
  position: absolute;
  content: "";
  left: -40px;
  top: 0;
  height: 30px;
  width: 30px;
  background: #ff3e66;
  border: 6px solid #2a182d;
  border-radius: 50%;
}
.mgga-features-box-item p {
  font-size: 14px;
  opacity: 0.6;
  color: #fff;
  margin-top: 3px;
}
.mgga-features-box-item:hover {
  border-color: #ff3e66;
}
.mgga-features-box-item.item-2 .title::before {
  background: #2eacfc;
  border-color: #15233d;
}
.mgga-features-box-item.item-2:hover {
  border-color: #2eacfc;
}
.mgga-features-box-item.item-3 .title::before {
  background: #33c956;
  border-color: #15262c;
}
.mgga-features-box-item.item-3:hover {
  border-color: #33c956;
}
.mgga-features-box-item.mgga-features-box-5-item .title {
  color: #0e1133;
}
.mgga-features-box-item.mgga-features-box-5-item .title::before {
  border-color: rgba(9, 8, 8, 0.21);
}
.mgga-features-box-item.mgga-features-box-5-item p {
  color: #505056;
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-features-thumb {
    text-align: center;
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .mgga-features-thumb {
    text-align: center;
    margin-top: 50px;
  }
}

.mgga-features-6-area .mgga-features-tabs-btn .nav a.active {
  color: #9b2cfa;
  border-right-color: #9b2cfa;
}
.mgga-features-6-area .mgga-features-tabs-btn .nav a.active i {
  background: #9b2cfa;
}
.mgga-features-6-area .mgga-features-content > span {
  color: #9b2cfa;
}
.mgga-features-6-area .mgga-features-content a {
  background: #9b2cfa;
  border-color: #9b2cfa;
}
.mgga-features-6-area .mgga-features-content a:hover {
  background: #fff;
  color: #9b2cfa;
}
.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #059dff;
  padding: 0 30px;
  font-size: 15px;
  line-height: 45px;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #059dff;
}
.main-btn:hover {
  background-color: #fff;
  color: #2b70fa;
  border-color: #2b70fa;
}
.main-btn.main-btn-2 {
  background-color: #fff;
  color: #2b70fa;
  border-color: #2b70fa;
}
.main-btn.main-btn-2:hover {
  background-color: #2b70fa;
  border-color: #2b70fa;
  color: #fff;
}

.mgga-about-8-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mgga-about-8-box {
  padding: 50px 50px 145px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-about-8-box {
    padding: 50px 25px 145px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-about-8-box {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .mgga-about-8-box {
    margin-bottom: 30px;
    padding: 50px 20px 145px;
  }
}
.mgga-about-8-box .thumb {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.mgga-about-8-box .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 14px;
}
@media (max-width: 767px) {
  .mgga-about-8-box .title {
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-about-8-box .title {
    font-size: 30px;
    line-height: 40px;
  }
}
.mgga-about-8-box a {
  border: 2px solid #059dff;
  color: white;
  background: #059dff;
  margin-top: 30px;
}
.mgga-about-8-box a:hover {
  color: #059dff;
  background: #ffffff;
  border-color: #059dff;
}

.mgga-section-title {
  padding-bottom: 25px;
}
.mgga-section-title .mgga-title {
  font-size: 44px;
  line-height: 54px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-section-title .mgga-title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-section-title .mgga-title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .mgga-section-title .mgga-title {
    font-size: 26px;
    line-height: 36px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-section-title .mgga-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.mgga-section-title p {
  font-size: 18px;
  line-height: 28px;
  margin-top: 11px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-section-title p {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-section-title p {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .mgga-section-title p {
    font-size: 15px;
  }
}
.mgga-section-title .main-btn {
  border-radius: 30px;
  border: 2px solid #e7eaef;
  background: #fff;
  color: #0e1133;
}
.mgga-section-title .main-btn:hover {
  background: #ff3e66;
  color: #fff;
  border-color: #ff3e66;
}
.mgga-section-title.mgga-section-title-2 .mgga-title {
  color: #fff;
}
.mgga-section-title.mgga-section-title-2 p {
  color: #fff;
}
.mgga-project-area .mgga-project-box {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 70px;
  padding-top: 75px;
  padding-bottom: 85px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .mgga-project-area .mgga-project-box {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-project-area .mgga-project-box {
    padding-left: 70px;
  }
}
.mgga-project-area .mgga-project-box .mgga-project-thumb {
  position: absolute;
  right: 70px;
  bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-project-area .mgga-project-box .mgga-project-thumb {
    right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-project-area .mgga-project-box .mgga-project-thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .mgga-project-area .mgga-project-box .mgga-project-thumb {
    display: none;
  }
}

.mgga-project-content {
  padding-right: 50px;
}
.mgga-project-content .title {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  padding-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-project-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .mgga-project-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-project-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}
.mgga-project-content p {
  color: #fff;
  opacity: 0.8;
}
.mgga-project-content .input-box {
  display: flex;
}
@media (max-width: 767px) {
  .mgga-project-content .input-box {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-project-content .input-box {
    display: flex;
  }
}
.mgga-project-content .input-box input {
  width: 100%;
  height: 50px;
  padding-left: 30px;
  border-radius: 6px;
  border: 0;
}
.mgga-project-content .input-box button {
  margin-left: 10px;
  padding: 0 30px;
  border-radius: 6px;
  padding: 0 25px;
  background: #0e1133;
  border: 0;
  color: #fff;
  line-height: 50px;
}
@media (max-width: 767px) {
  .mgga-project-content .input-box button {
    margin-top: 20px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-project-content .input-box button {
    margin-top: 0px;
    margin-left: 10px;
  }
}

.mgga-project-3-area.mgga-project-5-area {
  background: #eef1f6;
}

.mgga-project-3-box {
  background-color: #c59a6e;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 55px 70px;
  margin-bottom: -110px;
  position: relative;
  overflow: hidden;
  z-index: 10;
}
@media (max-width: 767px) {
  .mgga-project-3-box {
    text-align: center;
  }
}
.mgga-project-3-box .title {
  color: #fff;
  font-size: 46px;
  line-height: 56px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-project-3-box .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .mgga-project-3-box .title {
    font-size: 35px;
    line-height: 46px;
    margin-bottom: 30px;
  }
}
.mgga-project-3-box a {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
  line-height: 55px;
  padding: 0 40px;
}

/*===========================
    9. FAQ css 
===========================*/
.mgga-faq-area {
  background: #eef1f6;
  position: relative;
  background-position: 94% 100%;
  background-size: auto;
  background-repeat: no-repeat;
}

.faq-accrodion {
  margin-top: 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-accrodion {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .faq-accrodion {
    margin-top: 30px;
  }
}
.faq-accrodion .accrodion {
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.faq-accrodion .accrodion::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0px;
  right: 0px;
  border-radius: 0px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--thm-primary)),
    to(var(--thm-base))
  );
  background-image: linear-gradient(
    90deg,
    var(--thm-primary) 0%,
    var(--thm-base) 100%
  );
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  border-radius: 5px;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.faq-accrodion .accrodion + .accrodion {
  margin-top: 30px;
}
.faq-accrodion .accrodion.active {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}
.faq-accrodion .accrodion.active .accrodion-inner {
  margin: 0 1px;
  background-color: transparent;
  border-color: #fff;
}
.faq-accrodion .accrodion.active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.faq-accrodion .accrodion.active .accrodion-title {
  padding-bottom: 0;
}
.faq-accrodion .accrodion.active .accrodion-title::before {
  opacity: 0;
}
.faq-accrodion .accrodion.active .accrodion-title::after {
  opacity: 1;
  color: #fff;
}
.faq-accrodion .accrodion .accrodion-inner {
  background-color: #fff;
  padding: 18px 0;
  border-style: solid;
  border-width: 1px;
  border-color: #f2f4f8;
  border-radius: 0px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
}
.faq-accrodion .accrodion .accrodion-title {
  padding: 0px 38px;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-title {
    padding: 0 38px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .faq-accrodion .accrodion .accrodion-title::before {
    right: 5px;
  }
}
@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-title::before {
    right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .faq-accrodion .accrodion .accrodion-title::after {
    right: 5px;
  }
}
@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-title::after {
    right: 20px;
  }
}
.faq-accrodion .accrodion .accrodion-title h4 {
  margin: 0;
  color: #0e1133;
  font-size: 18px;
  font-weight: 700;
}
.faq-accrodion .accrodion .accrodion-content .inner {
  padding: 0 38px;
  padding-right: 35px;
  padding-top: 22px;
  padding-bottom: 0px;
}
@media (max-width: 767px) {
  .faq-accrodion .accrodion .accrodion-content .inner {
    padding: 0 38px 0 20px;
  }
}
.faq-accrodion .accrodion .accrodion-content p {
  margin: 0;
  color: #505056;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
}

.mgga-faq-8-area {
  background: #eef1f6;
  position: relative;
}
.mgga-faq-8-area
  .faq-accordion
  .accrodion
  .accrodion-inner
  .accrodion-title::before {
  background: #fcecef;
  color: #db0f30;
}
.mgga-faq-8-area
  .faq-accordion
  .accrodion
  .accrodion-inner
  .accrodion-title::after {
  background: #db0f30;
  color: #fff;
}
.mgga-faq-8-area .faq-play-box {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-faq-8-area .faq-play-box {
    display: none;
  }
}
@media (max-width: 767px) {
  .mgga-faq-8-area .faq-play-box {
    display: none;
  }
}
.mgga-faq-8-area .faq-play-box .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mgga-faq-8-area .faq-play-box .play-btn a {
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  background: #fff;
  border-radius: 50%;
  color: #2b70fa;
  position: relative;
}
.mgga-faq-8-area .faq-play-box .play-btn a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  animation: linear infinite popup 1.5s;
}
.mgga-faq-8-area .faq-play-box .faq-play-counter {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
}
.mgga-faq-8-area .faq-play-box .faq-play-counter .box-1 {
  background: rgba(42, 109, 245, 0.9);
  padding: 50px 60px;
}
.mgga-faq-8-area .faq-play-box .faq-play-counter .box-1 .title {
  font-size: 30px;
  color: #fff;
}
.mgga-faq-8-area .faq-play-box .faq-play-counter .box-1 span {
  font-size: 14px;
  color: #d1dbfb;
}
.mgga-faq-8-area .faq-play-box .faq-play-counter .box-1.box-2 {
  background: rgba(14, 17, 51, 0.9);
}
.custom-shape-divider-top-1729779338 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1729779338 svg {
  position: relative;
  display: block;
  width: calc(173% + 1.3px);
  height: 102px;
}

.custom-shape-divider-top-1729779338 .shape-fill {
  fill: #00008b;
}
.mgga-service-area {
  background: #eef1f6;
  position: relative;
  background-position: 92% 100%;
  background-repeat: no-repeat;
  background-size: auto;
}
.mgga-service-area.mgga-service-3-area {
  background: none;
}
@media (max-width: 767px) {
  .mgga-service-area.mgga-service-3-area {
    padding-top: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-service-area.mgga-service-3-area {
    padding-top: 190px;
  }
}

.mgga-single-service {
  padding: 50px 30px 43px;
  background: #fff;
  border-radius: 6px;
  transition: all linear 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-single-service {
    padding: 50px 15px 43px;
  }
}
.mgga-single-service .icon {
  display: inline-block;
  border-radius: 20px;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background: linear-gradient(135deg, #84bcfa 0%, #2b70fa 100%);
  box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
  position: relative;
}
.mgga-single-service .icon > span {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  background: #2b70fa;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 3px 6px 0px rgba(14, 17, 51, 0.1);
  line-height: 28px;
  color: #fff;
}
.mgga-single-service .mgga-title {
  font-size: 20px;
  font-weight: 700;
  padding-top: 33px;
  padding-bottom: 10px;
}
.mgga-single-service:hover {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  transform: translateY(-15px);
}
.mgga-single-service.item-2 .icon {
  background: linear-gradient(135deg, #f7b8af 0%, #f8452d 100%);
}
.mgga-single-service.item-2 .icon span {
  background: #f8452d;
}
.mgga-single-service.item-3 .icon {
  background: linear-gradient(135deg, #a0eac2 0%, #31c369 100%);
}
.mgga-single-service.item-3 .icon span {
  background: #31c369;
}
.mgga-single-service.item-4 .icon {
  background: linear-gradient(135deg, #d8aaff 0%, #9b2cfa 100%);
}
.mgga-single-service.item-4 .icon span {
  background: #9b2cfa;
}
.mgga-single-service.mgga-single-services-3 {
  background: transparent;
}
.mgga-single-service.mgga-single-services-3 .icon {
  border-radius: 50%;
  background-image: -moz-linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
  background-image: -webkit-linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
  background-image: -ms-linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
  background-image: linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
}
.mgga-single-service.mgga-single-services-3 a {
  color: #505056;
  font-size: 14px;
  font-weight: 500;
  margin-top: 25px;
}
.mgga-single-service.mgga-single-services-3 a:hover {
  color: #ff6b58;
}
.mgga-single-service.mgga-single-services-3.item-2 {
  background: linear-gradient(
    0deg,
    rgba(39, 153, 246, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
}
.mgga-single-service.mgga-single-services-3.item-2 .icon {
  background-image: -moz-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
  background-image: -webkit-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
  background-image: -ms-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
  background-image: linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
}
.mgga-single-service.mgga-single-services-3.item-3 .icon {
  background-image: -moz-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
  background-image: -webkit-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
  background-image: -ms-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
  background-image: linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
}
.mgga-single-service.mgga-single-services-3.item-4 {
  background: linear-gradient(
    0deg,
    rgba(119, 89, 237, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
}
.mgga-single-service.mgga-single-services-3.item-4 .icon {
  background-image: -moz-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
  background-image: -webkit-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
  background-image: -ms-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
  background-image: linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
}
.mgga-single-service.mgga-single-services-3.mgga-single-services-5 a:hover {
  color: #801f82;
}

@media (max-width: 767px) {
  .mgga-services-2-area .mgga-section-title {
    text-align: center !important;
  }
}

.mgga-single-service-2 {
  border: 1px solid #eef0f4;
  border-radius: 10px;
  padding: 48px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .mgga-single-service-2 {
    text-align: center;
    padding: 20px;
  }
}
.mgga-single-service-2 .icon i {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #2eacfc;
  color: #fff;
  border-radius: 50%;
}
.mgga-single-service-2 .title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}
.mgga-single-service-2 a {
  font-size: 14px;
  font-weight: 500;
  color: #505056;
  margin-top: 23px;
}
.mgga-single-service-2 a:hover {
  color: #ff3e66;
}
.mgga-single-service-2:hover {
  box-shadow: 0px -3px 0px 0px rgba(234, 76, 137, 0.004),
    0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  border-top: 3px solid #2eacfc;
  transform: translateY(-10px);
}
.mgga-single-service-2.item-2 .icon i {
  background: #ea4c89;
}
.mgga-single-service-2.item-2:hover {
  border-top-color: #ea4c89;
}
.mgga-single-service-2.item-3 .icon i {
  background: #3578ff;
}
.mgga-single-service-2.item-3:hover {
  border-top-color: #3578ff;
}
.mgga-single-service-2.item-4 .icon i {
  background: #f67741;
}
.mgga-single-service-2.item-4:hover {
  border-top-color: #f67741;
}
.mgga-single-service-2.item-5 .icon i {
  background: #6a88f7;
}
.mgga-single-service-2.item-5:hover {
  border-top-color: #6a88f7;
}
.mgga-single-service-2.item-6 .icon i {
  background: #3ab094;
}
.mgga-single-service-2.item-6:hover {
  border-top-color: #3ab094;
}
.mgga-single-service-2.mgga-single-service-about {
  border-radius: 0;
  padding: 0 90px 30px 0;
  border: 0;
  transition: all linear 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-single-service-2.mgga-single-service-about {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .mgga-single-service-2.mgga-single-service-about {
    padding-right: 50px;
    padding-left: 50px;
  }
}
.mgga-single-service-2.mgga-single-service-about .icon i {
  background-color: #f1f7fe;
  color: #2b70fa;
  border-radius: 35% 65% 50% 50% / 59% 50% 50% 41%;
  font-size: 20px;
}
.mgga-single-service-2.mgga-single-service-about:hover {
  box-shadow: none;
  margin-top: 20px;
}
.mgga-single-service-2.mgga-single-service-about.item-2 .icon i {
  color: #db0f30;
  background-color: #fef3f5;
  border-radius: 50% 50% 50% 50% / 50% 75% 25% 50%;
}
.mgga-single-service-2.mgga-single-service-about.item-3 .icon i {
  color: #9b2cfa;
  background-color: #f7eeff;
  border-radius: 25% 75% 50% 50% / 50% 50% 50% 50%;
}
.mgga-single-service-2.mgga-single-service-about.item-4 .icon i {
  color: #f8452d;
  background-color: #fef0ee;
  border-radius: 40% 60% 40% 60% / 40% 60% 40% 60%;
}
.mgga-single-service-2.mgga-single-service-about.item-5 .icon i {
  color: #31c369;
  background-color: #f4fcf7;
  border-radius: 33% 67% 50% 50% / 61% 72% 28% 39%;
}
.mgga-single-service-2.mgga-single-service-about.item-6 .icon i {
  color: #f6ac0e;
  background-color: #fef7e7;
  border-radius: 21% 79% 58% 42% / 50% 46% 54% 50%;
}

.mgga-services-6-area .mgga-section-title .main-btn:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
}
.mgga-services-6-area .mgga-single-service-2 a:hover {
  color: #9b2cfa;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .service-details-sidebar {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .service-details-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.service-details-sidebar .service-category-widget {
  border-radius: 6px;
  box-shadow: 0px 30px 50px 0px rgba(14, 17, 51, 0.1);
  padding: 30px;
  margin-bottom: 30px;
}
.service-details-sidebar .service-category-widget ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.service-details-sidebar .service-category-widget ul li {
  margin-bottom: 20px;
}
.service-details-sidebar .service-category-widget ul li i {
  color: #2b70fa;
  width: 15px;
  margin-right: 15px;
}
.service-details-sidebar .service-category-widget ul li:last-child {
  margin-bottom: 0;
}
.service-details-sidebar .service-download-widget {
  border-radius: 6px;
  margin-top: 10px;
  box-shadow: 0px 0px 50px 0px rgba(14, 17, 51, 0.1);
}
.service-details-sidebar .service-download-widget a {
  padding: 13px 30px;
  color: #505056;
}
.service-details-sidebar .service-download-widget a:hover {
  color: #2b70fa;
}
.service-details-sidebar .service-download-widget i {
  color: #2b70fa;
  padding-right: 15px;
}

.service-details-content .thumb {
  border-radius: 10px;
  overflow: hidden;
}
.service-details-content .content {
  padding-top: 34px;
}
.service-details-content .content .title {
  font-size: 30px;
  margin-bottom: 18px;
}
.service-details-content .content span {
  font-size: 20px;
  line-height: 30px;
  color: #0e1133;
  padding-top: 34px;
  padding-bottom: 26px;
}

.mgga-services-8-area .service-thumb {
  margin-left: -50px;
  margin-right: -100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-services-8-area .service-thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .mgga-services-8-area .service-thumb {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-counter-area {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .mgga-counter-area {
    padding-bottom: 100px;
  }
}
.mgga-counter-area.mgga-counter-5-area {
  background: #eef1f6;
}

.mgga-single-counter {
  padding: 30px;
  position: relative;
  background: #fff;
  height: 160px;
  background-image: -moz-linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
  background-image: -webkit-linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
  background-image: -ms-linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
  background-image: linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-single-counter {
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-single-counter {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .mgga-single-counter {
    margin-bottom: 90px;
  }
}
.mgga-single-counter .counter-content {
  background: #fff;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0px 30px 40px 0px rgba(0, 41, 57, 0.1);
  padding-top: 37px;
  padding-bottom: 40px;
}
.mgga-single-counter .counter-content .title {
  font-size: 40px;
  font-weight: 400;
  margin-top: 5px;
}
.mgga-single-counter .counter-content .title span {
  font-weight: 700;
}
.mgga-single-counter .counter-content p {
  font-size: 14px;
  font-weight: 500;
}
.mgga-single-counter.item-3 {
  background-image: -moz-linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
  background-image: -webkit-linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
  background-image: -ms-linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
  background-image: linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
}
.mgga-single-counter.item-3 .counter-content {
  box-shadow: 0px 30px 40px 0px rgba(56, 31, 0, 0.1);
}
.mgga-single-counter.item-4 {
  background-image: -moz-linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
  background-image: -webkit-linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
  background-image: -ms-linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
  background-image: linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
}
.mgga-single-counter.item-4 .counter-content {
  box-shadow: 0px 30px 40px 0px rgba(0, 74, 1, 0.1);
}
.mgga-single-counter.item-2 {
  background-image: -moz-linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
  background-image: -webkit-linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
  background-image: -ms-linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
  background-image: linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
}
.mgga-single-counter.item-2 .counter-content {
  box-shadow: 0px 30px 40px 0px rgba(74, 0, 54, 0.1);
}
.mgga-team-item .thumb {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.mgga-team-item .thumb img {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 100%;
}
.mgga-team-item .thumb::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 9;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.mgga-team-item .thumb ul {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.mgga-team-item .thumb ul li {
  display: inline-block;
  margin: 0 3px;
}

.mgga-team-item .thumb ul li a:hover {
  background: #fff;
  color: #2b70fa;
  border-color: #fff;
}
.mgga-team-item .content {
  padding-top: 27px;
}
.mgga-team-item .content .title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 8px;
}
.mgga-team-item .content span {
  font-size: 14px;
  color: #81818d;
}
.mgga-team-item:hover .thumb::before {
  opacity: 0.5;
}
.mgga-team-item:hover .thumb ul {
  opacity: 1;
  visibility: visible;
}
.mgga-team-item:hover .thumb img {
  transform: scale(1.1);
}
.mgga-team-item.mgga-team-item-about .thumb {
  border-radius: 6px;
  overflow: hidden;
}
.mgga-team-item.mgga-team-item-about .thumb::before {
  background-color: #2b70fa;
}
.mgga-team-item.mgga-team-item-about .thumb ul {
  left: 30px;
  transform: translateX(0);
}
.mgga-team-item.mgga-team-item-about .thumb ul li a {
  border-radius: 6px;
}
.mgga-team-item.mgga-team-item-about:hover .thumb::before {
  opacity: 0.9;
}
.mgga-team-item.mgga-team-item-about.mgga-team-item-8 .thumb::before {
  background: #db0f30;
}
.mgga-team-item.mgga-team-item-about.mgga-team-item-8 .thumb ul li a:hover {
  color: #db0f30;
}

.team-btn a {
  background: transparent;
  color: #0e1133;
  border: 2px solid #e7eaef;
}
.team-btn a i {
  padding-left: 6px;
}
.team-btn a:hover {
  background: #2b70fa;
  color: #fff;
}

.mgga-team-about-area .team-btn a {
  border-color: #2b70fa;
  color: #2b70fa;
}
.mgga-team-about-area .team-btn a:hover {
  color: #fff;
}
.mgga-team-about-area.mgga-team-8-area .team-btn a {
  border-color: #db0f30;
  color: #db0f30;
}
.mgga-team-about-area.mgga-team-8-area .team-btn a:hover {
  background: #db0f30;
  color: #fff;
}
.mgga-footer-area {
  background: #eef1f6;
  padding-top: 50px;
  padding-bottom: 60px;
}
.mgga-footer-area.mgga-footer-3-area {
  padding-top: 180px;
}
.mgga-footer-area.mgga-footer-3-area.mgga-footer-5-area {
  background: #fff;
}
.mgga-footer-area.mgga-footer-about-area {
  padding-top: 230px;
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area {
  background: #fff;
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-about-widget
  .social
  ul
  li
  a {
  border: 2px solid #e6e7ea;
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-about-widget
  .social
  ul
  li
  a:hover {
  background: #db0f30;
  border-color: #db0f30;
  color: #fff;
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-navigation
  ul
  li
  a:hover {
  color: var(--color-primary);
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-widget-info
  ul
  li
  a
  i {
  color: var(--color-primary);
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-copyright
  .apps-download-btn
  ul
  li
  a {
  background: #0e1133;
  color: #fff;
  border-color: #0e1133;
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-copyright
  .apps-download-btn
  ul
  li
  a:hover {
  background: #fff;
  color: #0e1133;
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-copyright
  .apps-download-btn
  ul
  li
  a.item-2 {
  background: #fff;
  color: #0e1133;
}
.mgga-footer-area.mgga-footer-about-area.mgga-footer-8-area
  .footer-copyright
  .apps-download-btn
  ul
  li
  a.item-2:hover {
  background: #0e1133;
  color: #fff;
  border-color: #0e1133;
}

.footer-about-widget {
  padding-right: 70px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .footer-about-widget {
    text-align: center;
    padding-right: 0;
  }
}
.footer-about-widget p {
  padding-top: 25px;
}
.footer-about-widget > a {
  font-size: 15px;
  font-weight: 500;
  color: #0e1133;
  margin-top: 15px;
}
.footer-about-widget > a i {
  padding-left: 6px;
}
.footer-about-widget .social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-about-widget .social ul li {
  display: inline-block;
}
.footer-about-widget .social ul li a {
  height: 36px;
  width: 36px;
  text-align: center;
  background: #fff;
  color: #0e1133;
  border-radius: 6px;
  line-height: 36px;
  margin-right: 5px;
}
.footer-about-widget .social ul li a:hover {
  background: #2b70fa;
  color: #fff;
}
.footer-about-widget.footer-about-widget-2 ul li a:hover {
  background: #ff3e66;
}
.footer-about-widget.footer-about-widget-3 ul li a:hover {
  background: #ff6b58;
}
.footer-about-widget.footer-about-widget-6 ul li a:hover {
  background: #9b2cfa;
}
.footer-about-widget.footer-about-widget-5 ul li a:hover {
  background: #801f82;
}

.footer-navigation {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .footer-navigation {
    text-align: center;
  }
}
.footer-navigation .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
}
.footer-navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-navigation ul li a {
  color: #505056;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 6px;
}
.footer-navigation ul li a:hover {
  color: #2b70fa;
}
.footer-navigation.footer-navigation-2 ul li a:hover {
  color: #ff3e66;
}
.footer-navigation.footer-navigation-3 ul li a:hover {
  color: #ff6b58;
}
.footer-navigation.footer-navigation-6 ul li a:hover {
  color: #9b2cfa;
}
.footer-navigation.footer-navigation-5 ul li a:hover {
  color: #801f82;
}

.footer-widget-info {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .footer-widget-info {
    text-align: center;
  }
}
.footer-widget-info .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
}
.footer-widget-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-widget-info ul li a {
  color: #505056;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}
.footer-widget-info ul li a i {
  position: absolute;
  left: 0;
  top: 0;
  color: #2b70fa;
}

.footer-copyright {
  padding-top: 35px;
  border-top: 1px solid #e1e5ec;
  margin-top: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-copyright .apps-download-btn {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer-copyright .apps-download-btn {
    margin-bottom: 20px;
  }
}
.footer-copyright .apps-download-btn ul {
  padding: 0;
  list-style-type: none;
}
.footer-copyright .apps-download-btn ul li {
  display: inline-block;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .footer-copyright .apps-download-btn ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-copyright .apps-download-btn ul li {
    margin-bottom: 0px;
  }
}
.footer-copyright .apps-download-btn ul li a {
  background: #fff;
  color: #0e1133;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 2px solid #fff;
}
.footer-copyright .apps-download-btn ul li a i {
  padding-right: 6px;
}
.footer-copyright .apps-download-btn ul li a:hover {
  background: transparent;
  color: #0e1133;
  border-color: #e7eaef;
}
.footer-copyright .apps-download-btn ul li a.item-2 {
  background: transparent;
  color: #0e1133;
  border-color: #e7eaef;
}
.footer-copyright .apps-download-btn ul li a.item-2:hover {
  background: #fff;
  color: #0e1133;
}

.mgga-error-area {
  padding-top: 250px;
}

.mgga-error-content span {
  display: block;
  font-size: 50px;
  color: #db0f30;
  font-weight: 800;
  padding-top: 40px;
}
.mgga-error-content .title {
  font-size: 50px;
  font-weight: 800;
  color: #0e1133;
  padding-bottom: 11px;
}
.mgga-error-content p {
  line-height: 26px;
  color: #505056;
  font-size: 16px;
  padding: 0 50px;
}
.mgga-error-content a {
  border: 2px solid #2b70fa;
  line-height: 45px;
  padding: 0 35px;
  border-radius: 6px;
  margin-top: 30px;
}

.mgga-signup-area.mgga-signup-8-area {
  background: #eef1f6;
}
.mgga-signup-area.mgga-signup-8-area .mgga-signup-box form .input-box button {
  background: #db0f30;
}

.mgga-signup-box {
  padding: 63px 100px 60px;
  border-radius: 10px;
  box-shadow: 0px 40px 50px 0px rgba(0, 24, 72, 0.3);
  overflow: hidden;
  position: relative;
  margin-bottom: -160px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-signup-box {
    padding: 30px 50px;
  }
}
@media (max-width: 767px) {
  .mgga-signup-box {
    padding: 30px;
  }
}
.mgga-signup-box .thumb {
  position: absolute;
  right: 70px;
  bottom: 30px;
  width: 160px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-signup-box .thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .mgga-signup-box .thumb {
    display: none;
  }
}
.mgga-signup-box span {
  color: #fff;
  font-weight: 500;
}
.mgga-signup-box .title {
  font-size: 46px;
  color: #fff;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .mgga-signup-box .title {
    font-size: 30px;
  }
}
.mgga-signup-box form {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-signup-box form {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .mgga-signup-box form {
    flex-direction: column;
  }
}
.mgga-signup-box form .input-box {
  margin-right: 10px;
  margin-top: 10px;
}
.mgga-signup-box form .input-box input {
  width: 300px;
  background: #fff;
  line-height: 55px;
  border-radius: 6px;
  padding-left: 30px;
  border: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-signup-box form .input-box input {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .mgga-signup-box form .input-box input {
    width: 100%;
  }
}
.mgga-signup-box form .input-box button {
  background: #2b70fa;
  line-height: 55px;
  border: 0;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  font-weight: 500;
}

.mgga_checkbox_common {
  margin-top: 15px;
}

.mgga_checkbox_common label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
}

.mgga_checkbox_common label a {
  text-decoration: underline;
  color: #b3b6bc;
}

.mgga_checkbox_common input[type="checkbox"] {
  display: none;
}

.mgga_checkbox_common input[type="checkbox"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
}

.mgga_checkbox_common input[type="checkbox"] + label span:before {
  color: #fff;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  position: absolute;
  text-align: center;
  left: 3px;
  top: -1px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.mgga_checkbox_common input[type="checkbox"]:checked + label span {
  background: #2079ff;
  border-color: #2079ff;
}

.mgga_checkbox_common input[type="checkbox"]:checked + label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.mgga_checkbox_common input[type="checkbox"] + label span,
.mgga_checkbox_common input[type="checkbox"]:checked + label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.mgga_checkbox_common.checkbox_style2 input[type="checkbox"] + label span {
  border-radius: 3px;
}
.mgga-home-cta-btn {
  background: #2b70fa;
  line-height: 55px;
  border: 0;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  font-weight: 500;
}
.mgga-quadrant-area {
  position: relative;
  overflow: hidden;
  z-index: 15;
}
.mgga-quadrant-area .quadrant-thumb {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 42%;
}
@media (max-width: 767px) {
  .mgga-quadrant-area .quadrant-thumb {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-quadrant-area .quadrant-thumb {
    display: none;
  }
}

.mgga-quadrant-title {
  padding-right: 220px;
  margin-bottom: 43px;
}
@media (max-width: 767px) {
  .mgga-quadrant-title {
    padding-right: 0;
  }
}
.mgga-quadrant-title span {
  font-size: 14px;
  font-weight: 500;
  color: #2b70fa;
}
.mgga-quadrant-title .title {
  font-size: 44px;
  line-height: 54px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-quadrant-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-quadrant-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .mgga-quadrant-title .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.mgga-quadrant-service {
  position: relative;
  padding-left: 40px;
  padding-right: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-quadrant-service {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-quadrant-service {
    margin-bottom: 30px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .mgga-quadrant-service {
    margin-bottom: 30px;
    padding-right: 0px;
  }
}
.mgga-quadrant-service .icon {
  position: absolute;
  left: 0;
  top: 5px;
}
.mgga-quadrant-service .icon i {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  background: linear-gradient(135deg, #84bcfa 0%, #2b70fa 100%);
  color: #fff;
  font-size: 14px;
  box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
}
.mgga-quadrant-service .title {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 7px;
}
.mgga-quadrant-service p {
  font-size: 15px;
}
.mgga-quadrant-service.item-2 .icon i {
  background: linear-gradient(135deg, #f7b8af 0%, #f8452d 100%);
}
.mgga-quadrant-service.item-3 .icon i {
  background: linear-gradient(135deg, #a0eac2 0%, #31c369 100%);
}
.mgga-quadrant-service.item-4 .icon i {
  background: linear-gradient(135deg, #d8aaff 0%, #9b2cfa 100%);
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .quadrant-btn {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .quadrant-btn {
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .quadrant-btn {
    text-align: left;
  }
}
.quadrant-btn a.main-btn {
  background: transparent;
  color: #0e1133;
  border: 2px solid #e7eaef;
}
.quadrant-btn a.main-btn i {
  padding-left: 6px;
}
.quadrant-btn a.main-btn:hover {
  background: #2b70fa;
  color: #fff;
}
input[type="checkbox"]:checked ~ #implant-checkbox::before {
  background-color: #e23e96;
}
input[type="checkbox"]:checked ~ #equipment-checkbox::before {
  background-color: #e23e96;
}
input[type="checkbox"]:checked ~ #termscheck::before {
  background-color: #e23e96;
}

.contact-section {
  position: relative;
  padding: 120px 0;
  background: #f7f6fa;
}

.contact--info-area {
  position: relative;
  margin-right: 40px;
}

.contact--info-area h3 {
  font-size: 30px;
  color: #2c234d;
  font-weight: 800;
  line-height: 0.8;
  margin-bottom: 16px;
}

.contact--info-area > p {
  color: #686875;
  line-height: 24px;
  border-bottom: 1px solid #e5e4ed;
  margin-bottom: 33px;
  padding-bottom: 25px;
}

.single-info {
  position: relative;
  margin-bottom: 37px;
}

.single-info h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2c234d;
}

.single-info p {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #686875;
  margin: 0;
  padding-left: 32px;
}

.single-info p i {
  font-size: 16px;
  line-height: 22px;
  color: #2b70fa;
  position: absolute;
  left: 0;
  top: 0;
}

.ab-social h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #2c234d;
}

.bisylms-map {
  position: relative;
  line-height: 0.8;
  overflow: hidden;
}

.bisylms-map iframe {
  width: 100%;
  height: 480px;
  border: none;
}

.contact-form {
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 43px 50px 65px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(11, 2, 55, 0.06);
  -moz-box-shadow: 0px 1px 3px 0px rgba(11, 2, 55, 0.06);
  box-shadow: 0px 1px 3px 0px rgba(11, 2, 55, 0.06);
}

.contact-form h4 {
  font-size: 24px;
  color: #2c234d;
  line-height: 30px;
  margin-bottom: 8px;
}

.contact-form p {
  font-size: 16px;
  color: #686875;
  line-height: 26px;
  position: relative;
  z-index: 3;
  margin: 0 0 41px;
}

.contact-form input[type="text"],
.contact-form input[type="number"],
.contact-form textarea,
.contact-form input[type="email"],
.contact-form select {
  width: 100%;
  height: 56px;
  font-size: 14px;
  line-height: 56px;
  font-weight: 400;
  border-radius: 3px;
  border: none;
  background: #f5f5f7;
  color: #7e7c87;
  outline: none;
  border: 1px solid transparent !important;
  padding: 0 30px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contact-form textarea {
  height: 220px;
  resize: none;
  line-height: 30px;
  padding-top: 14px;
}

.contact-form textarea::-moz-placeholder,
.contact-form input[type="text"]::-moz-placeholder,
.contact-form input[type="number"]::-moz-placeholder,
.contact-form input[type="email"]::-moz-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.contact-form textarea::-ms-input-placeholder,
.contact-form input[type="text"]::-ms-input-placeholder,
.contact-form input[type="number"]::-ms-input-placeholder,
.contact-form input[type="email"]::-ms-input-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.contact-form textarea::-webkit-input-placeholder,
.contact-form input[type="text"]::-webkit-input-placeholder,
.contact-form input[type="number"]::-webkit-input-placeholder,
.contact-form input[type="email"]::-webkit-input-placeholder {
  color: #7e7c87;
  opacity: 1;
}

.contact-form textarea:focus,
.contact-form input[type="text"]:focus,
.contact-form input[type="number"]:focus,
.contact-form input[type="email"]:focus {
  background: transparent;
  border-color: #ece9f9;
  -webkit-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
  -moz-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
  box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
}

.contact-form input[type="submit"] {
  height: 50px;
  border-radius: 3px;
  background: #2b70fa;
  color: #fff;
  text-align: center;
  border: none;
  outline: none;
  padding: 0 36px;
  font-size: 13px;
  line-height: 50px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contact-form input[type="submit"]:hover {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
  -moz-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
  box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
}

.condition-check {
  position: relative;
  margin: 10px 0 0;
}

.condition-check input[type="checkbox"] {
  display: none;
}

.condition-check label {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  padding-left: 31px;
  position: relative;
  cursor: pointer;
  color: #686875;
  margin: 0 0 0;
}

.condition-check label a {
  color: #2b70fa;
}

.condition-check label a:hover {
  color: #686875;
}

.condition-check label:before {
  background: transparent;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  border-radius: 3px;
  top: 3px;
  width: 16px;
  border: 2px solid #ababb1;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.condition-check input:checked + label::after {
  background: transparent;
  bottom: 0;
  color: #2b70fa;
  font-size: 10px;
  position: absolute;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ab-social {
  position: relative;
}

.ab-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #446bb9;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 37px;
  font-size: 14px;
  margin-right: 2px;
}

.ab-social a.fac {
  background: #446bb9;
}

.ab-social a.inst {
  background: #ec008c;
}

.ab-social a.you {
  background: #f73434;
}

.ab-social a.lin {
  background: #007bb6;
}

.ab-social a:hover {
  -webkit-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
  -moz-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
  box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
}

.mgga-page-title-area {
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.mgga-page-title-area.mgga-page-service-title-area {
  height: 650px;
  background-image: url(https://placehold.co/1920x400);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-page-title-area.mgga-page-service-title-area {
    height: 500px;
  }
}
@media (max-width: 767px) {
  .mgga-page-title-area.mgga-page-service-title-area {
    height: 400px;
  }
}
.mgga-page-title-area.mgga-page-service-title-area
  .mgga-page-title-item
  .thumb {
  right: 110px;
  width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mgga-page-title-area.mgga-page-service-title-area
    .mgga-page-title-item
    .thumb {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mgga-page-title-area.mgga-page-service-title-area
    .mgga-page-title-item
    .thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .mgga-page-title-area.mgga-page-service-title-area
    .mgga-page-title-item
    .thumb {
    display: none;
  }
}

.mgga-page-title-item {
  margin-top: 80px;
  position: relative;
}
.mgga-page-title-item .thumb {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 160px;
}
@media (max-width: 767px) {
  .mgga-page-title-item .thumb {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-page-title-item .thumb {
    display: block;
  }
}
.mgga-page-title-item span {
  color: #fff;
  font-weight: 500;
  opacity: 0.7;
  padding-bottom: 14px;
}
.mgga-page-title-item .title {
  color: #fff;
  font-size: 44px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .mgga-page-title-item .title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mgga-page-title-item .title {
    font-size: 32px;
  }
}
.mgga-page-title-item nav {
  display: inline-block;
  margin-top: 20px;
}
.mgga-page-title-item nav ol {
  background: transparent;
  padding: 0;
}
.mgga-page-title-item nav ol li a {
  color: #fff;
  opacity: 0.8;
}
.mgga-page-title-item nav ol li.active {
  color: #fff;
  opacity: 0.8;
}

.sales-email,
.sales-tel {
  color: #65676b;
}
/*--- Sidebar ---*/
.blog-sidebar {
  position: relative;
  margin-left: 30px;
}

.blog-sidebar .widget {
  position: relative;
  border: 1px solid #ebecf1;
  border-radius: 10px;
  margin: 0 0 30px;
  padding: 32px 30px 37px;
}

.blog-sidebar .widget .widget-title {
  color: #2c234d;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  border-bottom: 1px solid #cfd2de;
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
  margin: 0 0 17px;
}

.blog-sidebar .widget.widget-search {
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
}

.blog-sidebar .widget.widget-search .widget-title {
  display: none;
}

.search-form {
  position: relative;
}

.search-form input[type="search"] {
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  border-radius: 3px;
  font-weight: 500;
  border: none;
  background: #f1f0f5;
  color: #8f8d98;
  outline: none;
  padding: 0 25px;
}

.search-form input[type="search"]::-moz-placeholder {
  color: #8f8d98;
  opacity: 1;
}

.search-form input[type="search"]::-ms-input-placeholder {
  color: #8f8d98;
  opacity: 1;
}

.search-form input[type="search"]::-webkit-input-placeholder {
  color: #8f8d98;
  opacity: 1;
}

.search-form button {
  font-size: 16px;
  line-height: 52px;
  color: #2c234d;
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 17px;
  top: 0;
  z-index: 2;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.search-form button:hover {
  color: #2b70fa;
}

.blog-sidebar .widget ul {
  margin: 0;
  padding: 0;
}

.blog-sidebar .widget ul li {
  list-style: none;
  display: block;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  color: #5d5a67;
  position: relative;
  padding-left: 25px;
}

.blog-sidebar .widget ul li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
  line-height: 40px;
  font-weight: 300;
  color: #b2b0bc;
}

.blog-sidebar .widget ul li a {
  color: inherit;
  display: inline-block;
}

.blog-sidebar .widget ul li a:hover {
  color: #2b70fa;
}

.blog-sidebar .widget ul li span {
  color: #b2b0bc;
  margin-left: 9px;
}

svg {
  display: inline !important;
}
